// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--ee835";
export var animateScrollOut = "styles-module--animateScrollOut--26eeb";
export var bottomLink = "styles-module--bottomLink--2a033";
export var card = "styles-module--card--1ab4b";
export var cardTexts = "styles-module--cardTexts--b6061";
export var cardsContainer = "styles-module--cardsContainer--2aa79";
export var colorSequence = "styles-module--colorSequence--af18d";
export var container = "styles-module--container--340b3";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--55933";
export var dot = "styles-module--dot--eca05";
export var grow = "styles-module--grow--658b6";
export var growAndShrink = "styles-module--growAndShrink--8d5bf";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--400cb";
export var image = "styles-module--image--5805e";
export var label = "styles-module--label--760b1";
export var moveBg = "styles-module--move-bg--e6ae9";
export var rotateLoop = "styles-module--rotateLoop--c774b";
export var section = "styles-module--section--facd1";
export var smallDesc = "styles-module--smallDesc--0a4f0";
export var spin = "styles-module--spin--dd0ba";
export var spinCounter = "styles-module--spinCounter--da18b";
export var titleContainer = "styles-module--titleContainer--77c35";